import { AxiosInstance } from 'axios'

import { AccountService } from './services/AccountService'

export class AuthApi {
  public readonly account: AccountService

  constructor(public readonly request: AxiosInstance) {
    this.account = new AccountService(this.request)
  }
}
