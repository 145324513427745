import { Result, arrayIncludes, objValues, urlApp } from '@lasso/shared/utils'

import { useApiCombined, useApiManual } from '@lasso/shared/hooks'
import { useRouter } from 'vue-router'

import { nextTick } from 'vue'

import { AccountLoginResponse, AccountOAuthProviderEnum } from '../../api'
import { useAuthApi } from '../useAuthApi'

import { AuthUnknownError, LoginWithOAuthError } from './types'

export const useAuthSso = ({ setTokenAndRedirect }: {
  setTokenAndRedirect: (token: AccountLoginResponse) => Promise<Result<null, AuthUnknownError>>
}) => {
  const api = useAuthApi()
  const router = useRouter()

  const getOAuthClientIdApi = useApiManual(api.getOAuthClientId)
  const signInWithOAuthCodeApi = useApiManual(api.signInWithOAuthCode)

  const getSSOReturnUrl = () => {
    // TODO: update sso configurations to accept the new url
    return urlApp('/v2/auth')
  }

  const getOAuthGoogleEndpoint = (clientId: string): string => {
    const url = new URL('https://accounts.google.com/o/oauth2/v2/auth')
    url.searchParams.set('response_type', 'code')
    url.searchParams.set('client_id', clientId)
    url.searchParams.set('redirect_uri', getSSOReturnUrl())
    url.searchParams.set('scope', 'email')
    url.searchParams.set('state', `lasso_${AccountOAuthProviderEnum.google}`)
    url.searchParams.set('prompt', 'consent')

    return url.toString()
  }

  const getOAuthMicrosoftEndpoint = (clientId: string): string => {
    const url = new URL('https://login.microsoftonline.com/common/oauth2/v2.0/authorize')
    url.searchParams.set('response_type', 'code')
    url.searchParams.set('client_id', clientId)
    url.searchParams.set('redirect_uri', getSSOReturnUrl())
    url.searchParams.set('scope', 'openid email')
    url.searchParams.set('state', `lasso_${AccountOAuthProviderEnum.microsoft}`)
    url.searchParams.set('prompt', 'consent')
    url.searchParams.set('response_mode', 'query')

    return url.toString()
  }

  const getOAuthUrl = async (provider: AccountOAuthProviderEnum): Promise<Result<string, AuthUnknownError>> => {
    const response = await getOAuthClientIdApi.requestThrows(provider)

    if (!response) {
      return Result.err({ code: 'unknown', details: null })
    }

    switch (provider) {
      case AccountOAuthProviderEnum.google:
        return Result.ok(getOAuthGoogleEndpoint(response.data))
      case AccountOAuthProviderEnum.microsoft:
        return Result.ok(getOAuthMicrosoftEndpoint(response.data))
    }
  }

  const loginWithOAuthInternal = async (urlParams: URLSearchParams): Promise<Result<null, LoginWithOAuthError | AuthUnknownError>> => {
    const code = urlParams.get('code')
    const lassoState = urlParams.get('state')

    if (!code || !lassoState) {
      return Result.err({ code: 'noParams' })
    }

    const provider = lassoState.replace('lasso_', '')

    if (!arrayIncludes(objValues(AccountOAuthProviderEnum), provider)) {
      return Result.err({ code: 'unknownProvider', details: { provider } })
    }

    try {
      const response = await signInWithOAuthCodeApi.requestThrows(provider, code)

      if (!response) {
        return Result.err({ code: 'unknown', details: null })
      }

      if (response.data) {
        return await setTokenAndRedirect(response.data)
      }
      else {
        return Result.err({ code: 'registrationRequired' })
      }
    }
    catch (error) {
      return Result.err({ code: 'unknown', details: error as Error })
    }
    finally {
      await router.replace({ query: {} })
      await nextTick()
    }
  }

  const { loading: loadingSso, requestThrows: loginWithOAuth } = useApiManual(loginWithOAuthInternal)

  return {
    getOAuthUrl,
    loginWithOAuth,
    ssoApi: useApiCombined([
      getOAuthClientIdApi,
      signInWithOAuthCodeApi,
    ]),
    loadingSso,
  }
}
