import { createInjectionState } from '@lasso/shared/utils'
import { AxiosInstance } from 'axios'
import { bindApiMethods } from '@lasso/api-shared'

import { useApiCache } from '@lasso/shared/hooks'

import { AuthApi } from '../api'

export const [useProvideAuthApi, useAuthApi] = createInjectionState(
  (axiosInstance: AxiosInstance) => {
    const [{ account }, methodsMapping] = bindApiMethods(new AuthApi(axiosInstance))

    useApiCache().aliasCacheKeys(methodsMapping)

    return account
  },
  { throwWhenNotProvided: 'Auth API was not provided' },
)
