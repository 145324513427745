import { Result } from '@lasso/shared/utils'

import { object, string } from 'yup'
import { ApiError } from '@lasso/api-shared'
import { reactive } from 'vue'
import { useApiManual } from '@lasso/shared/hooks'

import { AccountLoginRequestType, AccountLoginResponse } from '../../api'
import { useAuthApi } from '../useAuthApi'

import { AuthUnknownError, LoginError, LoginSuccess } from './types'

export const useAuthEmail = ({ setTokenAndRedirect }: {
  setTokenAndRedirect: (token: AccountLoginResponse) => Promise<Result<null, AuthUnknownError>>
}) => {
  const api = useAuthApi()
  const loginApi = reactive(useApiManual(api.login))

  const login = async (data: AccountLoginRequestType): Promise<Result<LoginSuccess, LoginError | AuthUnknownError>> => {
    const passwordExpiredErrorSchema = object({
      error: string().oneOf(['password_expired']).required(),
      data: object({
        userId: string().required(),
        code: string().required(),
      }).required(),
    })

    const badRequestErrorSchema = object({
      error_description: string().required(),
    })

    let response
    try {
      response = await loginApi.requestThrows(data)
    }
    catch (error) {
      if (error instanceof ApiError) {
        if (error.isDataMatching(passwordExpiredErrorSchema)) {
          return Result.err({ code: 'passwordExpired', details: error.data.data })
        }
        else if (error.isDataMatching(badRequestErrorSchema)) {
          return Result.err({
            code: 'badRequest',
            details: { description: error.data.error_description },
          })
        }
      }

      return Result.err({ code: 'unknown', details: error as Error })
    }

    if (!response) {
      return Result.err({ code: 'unknown', details: null })
    }

    const result = await setTokenAndRedirect(response.data)

    return result.map(() => ({ userName: response.data.userName }))
  }

  return {
    login,
    loginApi,
  }
}
